var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"94673286137a50f8a89db58c4d22ca40442b2f79"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/s/app/2024/nm-namshi-frontend/94673286137a50f8a89db58c4d22ca40442b2f79/bigalog/_next/_static";

import { ClientApp } from '@nm-namshi-frontend/core/types';
import { initializeSentry } from '@nm-namshi-frontend/core/utils/sentry';

initializeSentry(ClientApp.BIGALOG);

import { ReactElement, ReactNode } from 'react';

import { NextPage } from 'next';
import { AppProps } from 'next/app';

import { Timer } from '@nm-namshi-frontend/services/models/Timer';
import { TRegionAPI } from '@nm-namshi-frontend/core/types';
import { TConfigIcons } from '@nm-namshi-frontend/core/theme/icon';
import {
    ProductFullResponse,
    ProductSimpleResponse,
    ProductSuggestion,
    ProductWishlistResponse,
} from '@nm-namshi-frontend/services';

import { TLocaleSEO } from './locale';

/**
 * MAP types
 */
export enum AddressTypes {
    LATLNG = 'latlng',
    COLLECTION = 'collection',
    GIFT = 'gift',
}

export enum CollectionTypes {
    LOCKER = 'locker',
    PICKUP_POINT = 'pickup_point',
}

export type TMarker = {
    icon: string;
    key: string;
    position: { lat: number; lng: number };
};

export type TDevice = 'desktop' | 'mobile';

export enum ProductBoxVariant {
    FULL = 'full',
    NORMAL = 'normal',
    SEARCHSUGGESTION = 'searchSuggestion',
    WISHLIST = 'wishlist',
    CROSS_SEARCH = 'crossSearch',
}

export enum ClientApp {
    DEFAULT = 'www',
    BIGALOG = 'bigalog',
    SCHMATALOG = 'schmatalog',
    ACCOUNT = 'account',
    CHECKOUT_V1 = 'checkout', // Only relevant for V1 checkout ie. when redirecting users with cookie V1
    ACCOUNT_V1 = 'account-v1',
    APP = 'app',
    CMS = 'cms',
}

export enum ServerPageType {
    SESSION = 'session',
    ORDER = 'order',
    PRODUCT = 'product',
    PAYMENT = 'payment',
}

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    params: { locale: TLocaleSEO };
};

// ---------------------------@Todo - Ehab : CLEAN THE BELOW POST HEADER DEPARTMENT TABS ROLLOUT for mweb + dweb--------------------------- //

export type TDepartmentCode = 'men' | 'women' | 'kids'; // Used in legacy department related implementation

export type TSubDepartmentId = 'men' | 'women' | 'kids'; // Used in the newer tabs implementation, until we eventually sync category + search popup to be in line with the global tab=driven department + subdepartment setup

// ---------------------------@Todo - Ehab : CLEAN THE ABOVE POST HEADER DEPARTMENT TABS ROLLOUT for mweb + dweb--------------------------- //

type TFeatureName = 'customer_migration';
type TRule =
    | {
          name: 'employee';
      }
    | { name: 'country'; data: TRegionAPI[] }
    | { name: 'startDate'; data: string }
    | {
          name: 'endDate';
          data: string;
      };

export type TFeatureToggles = Record<
    TFeatureName,
    {
        active: boolean;
        rules?: TRule[];
    }
>;

export type TApiConfig = {
    timer_sale: Timer[];
    feature_toggles: TFeatureToggles;
};

export type TSearchSuggestionsTracking = 'Did you Mean' | 'Categories' | 'Brands' | 'Products';

export type TOrderListTracking = { sku: string; isRocket: 0 | 1 };

export type TOption = {
    label: string;
    value: any;
};

export type TPostOrderAction =
    | 'cancelShipmentClick'
    | 'cancelInfoClick'
    | 'cancelItemClick'
    | 'cancelOrderClick'
    | 'cancelButtonClick'
    | 'refundWalletClick'
    | 'refundReferenceClick'
    | 'reviewGoBackClick'
    | 'tplTrackClick'
    | 'helpClick';

export type THelpModuleAction = 'searchClick' | 'filterClick' | 'helpQuestionClick' | 'ItemClick';

export type TGenericButtonClickAction = 'colorGroupClick' | 'continueShopping' | 'goToBag' | 'close' | 'checkout';

export type TBottomTab = {
    label: string;
    linkUrl: string;
    icon: TConfigIcons;
    code: 'home' | 'category' | 'bag' | 'wishlist' | 'account' | 'looks' | 'page';
    isExternal?: true;
    iconVersion?: number;
};

export type TSuperProduct = ProductFullResponse | ProductSimpleResponse | ProductSuggestion | ProductWishlistResponse;
